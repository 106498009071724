import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useContext } from 'react';
import { AppContext } from '../hooks/AppProvider';

function ListCategory() {
    const { t } = useTranslation()
    const { categories, language } = useContext(AppContext);
    return (
        <>
            <section className="elementor-section-with-custom-width elementor-section elementor-top-section elementor-element elementor-element-9b46b3a elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static" data-element_type="section" data-id="9b46b3a">
                <div className="elementor-container elementor-column-gap-extended">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1f6cece sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static" data-element_type="column" data-id="1f6cece">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-6f3102b sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="6f3102b" data-widget_type="spacer.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-spacer">
                                        <div className="elementor-spacer-inner" />
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-b9e77dc sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-element_type="widget" data-id="b9e77dc" data-widget_type="trx_sc_title.default">
                                <div className="elementor-widget-container">
                                    <div className="sc_title sc_title_default">
                                        <h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag sc_typed">
                                            <span className="sc_main_title_text">
                                                {t("categories")}
                                            </span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-754ce47 sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="754ce47" data-widget_type="spacer.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-spacer">
                                        <div className="elementor-spacer-inner" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="elementor-section elementor-top-section elementor-element elementor-element-cb583d3 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static" data-element_type="section" data-id="cb583d3">
                <div className="elementor-container elementor-column-gap-extended">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f624999 sc_content_align_left sc_layouts_column_align_left sc_layouts_column sc_inner_width_none sc_layouts_column_icons_position_left sc_fly_static"
                    data-element_type="column" data-id="f624999">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-b25bc70 sc_fly_static elementor-widget elementor-widget-trx_widget_slider" data-element_type="widget" data-id="b25bc70" data-widget_type="trx_widget_slider.default">
                                <div className="elementor-widget-container">
                                    <div className="widget_area sc_widget_slider">
                                        <aside className="widget widget_slider">
                                            <div className="slider_wrap slider_engine_swiper">
                                                <div className="slider_outer slider_swiper_outer slider_style_default slider_source_custom slider_outer_direction_horizontal slider_outer_multi slider_outer_nopagination slider_outer_nocontrols slider_outer_nocentered slider_outer_overflow_visible slider_outer_notitles slider_outer_height_auto">
                                                    <Swiper
                                                        modules={[Autoplay]} // Include Autoplay in modules
                                                        loop={true}
                                                        autoplay={{
                                                            delay: 2000,
                                                            disableOnInteraction: false // Ensures autoplay continues after user interaction
                                                        }}
                                                        speed={500}
                                                        pagination={{ clickable: true }}
                                                        navigation
                                                        style={{ overflow: 'visible', height: 'auto' }}
                                                        className='slider_container slider_swiper swiper-slider-container slider_direction_horizontal slider_nopagination slider_multi slider_type_images slider_nocontrols slider_nocentered slider_overflow_visible slider_notitles slider_noresize slider_swipe slider_height_auto inited swiper-container-initialized swiper-container-horizontal'
                                                        breakpoints={{
                                                            320: { slidesPerView: 1, spaceBetween: 20 },
                                                            640: { slidesPerView: 1, spaceBetween: 20 },
                                                            768: { slidesPerView: 2, spaceBetween: 20 },
                                                            1024: { slidesPerView: 3, spaceBetween: 20 },
                                                        }}
                                                    >
                                                    {
                                                        categories.map((category, index) => (
                                                            <SwiperSlide key={index} className='slider-slide'>
                                                                <NavLink to={`/product?category=${category.id}`} className='image-container'>
                                                                    <img alt={category.name_en} src={category.image} decoding="async"/>
                                                                    <div className='centered-frame'>
                                                                        <span className='centered-text'>{language === 'en' ? category.name_en : category.name_vi}</span>
                                                                    </div>
                                                                </NavLink>
                                                            </SwiperSlide>
                                                        ))
                                                    }
                                                    </Swiper>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ListCategory;
