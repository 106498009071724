import { useState } from 'react';
import BackDrop from '../components/BackDrop';
import contactApi from '../api/contactApi';
import { useTranslation } from 'react-i18next';

function Contact() {
    const { t } = useTranslation()
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        description: '',
    });

    // State to manage validation errors
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

    const validate = () => {
        let formErrors = {};
        let isValid = true;

        if (formData.name && formData.name.length >= 100) {
            isValid = false;
            formErrors.name = 'Full name must be less than 100 characters';
        }

        if (!formData.email) {
            isValid = false;
            formErrors.email = t("form.required_email");
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            isValid = false;
            formErrors.email = t("form.regex_email");
        }

        if (formData.subject && formData.subject.length >= 100) {
            isValid = false;
            formErrors.name = t("form.max_subject");
        }

        // Validate phone
        if (formData.phone && !/^0\d{9}$/.test(formData.phone)) {
            isValid = false;
            formErrors.phone = t("form.regex_phone");
        }

        if (!formData.description) {
            isValid = false;
            formErrors.description = t("form.required_description");
        } else if (formData.description.length >= 300) {
            isValid = false;
            formErrors.description = t("form.max_description");
        }

        setErrors(formErrors);
        return isValid;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        if (validate()) {
            try {
                await contactApi.postContact(formData)
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    description: '',
                });
                setSuccessMessage(t("form.send_contact_success"))
            } catch (error) {
                setErrorMessage(t("form.send_contact_failed"))
            }
        }
    };

    return (
        <>
            <div className="content">
            <BackDrop title={t("contact")} />
            <article id="backdrop-down-scroll" className="post_item_single post_type_page post-29527 page type-page status-publish hentry">
            <div className="post_content entry-content">
                <div data-elementor-type="wp-page" className="elementor elementor-29527">
                <section className="elementor-section elementor-top-section elementor-element elementor-element-792bff88 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="792bff88" data-element_type="section">
                    <div className="elementor-container elementor-column-gap-extended">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-34a051e sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="34a051e" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-69cb39f8 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="69cb39f8" data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                                <div className="elementor-spacer-inner" />
                            </div>
                            </div>
                        </div>
                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-7dcb41de elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="7dcb41de" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-no">
                            <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1d4f030 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="1d4f030" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated" style={{ padding: '0% 29% 0% 0%' }}>
                                <div className="elementor-element elementor-element-e5e768c sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="e5e768c" data-element_type="widget" data-widget_type="trx_sc_title.default">
                                    <div className="elementor-widget-container">
                                        <div className="sc_title sc_title_default">
                                            <span className="sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default">{t("contact_us")}</span>
                                            <h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag" style={{lineHeight: "1.2em"}}>
                                                <span className="sc_item_title_text">{t("get_in_touch")}!</span>
                                            </h1>
                                            <div className="sc_item_descr sc_title_descr">
                                                <p>{t("contact_description")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="elementor-element elementor-element-1220e734 sc_fly_static elementor-widget elementor-widget-trx_sc_icons trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="1220e734" data-element_type="widget" data-widget_type="trx_sc_icons.default" style={{marginTop: '20px'}}>
                                    <div className="elementor-widget-container">
                                        <div className="sc_icons sc_icons_simple sc_icons_size_small sc_align_none">
                                            <div className="sc_icons_item with_more">
                                                <div className="sc_icons_icon sc_icon_type_ icon-marker" style={{color: '#B55329'}}></div>
                                                <div className="sc_icons_item_details">
                                                    <div className="sc_icons_item_description">
                                                        <span>40 Hai Thuong Lan Ong St.,Hoi An, Quang Nam</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sc_icons_item sc_icons_item_linked with_more">
                                                <div className="sc_icons_icon sc_icon_type_ icon-iphone" style={{color: '#B55329'}}></div>
                                                <div className="sc_icons_item_details">
                                                    <a href="tel:0787696763" className="sc_icons_item_more_link">
                                                        <span className="link_text" style={{fontFamily: 'RobotoCondensed-Regular'}}>0787 69 67 63</span>
                                                        <span className="link_icon" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="sc_icons_item with_more">
                                                <div className="sc_icons_icon sc_icon_type_ icon-envelope-2" style={{color: '#B55329'}}></div>
                                                <div className="sc_icons_item_details">
                                                    <div className="sc_icons_item_description">
                                                        <span>
                                                            <a href="mailto:ducthanhlantern@gmail.com" className="underline_hover">ducthanhlantern@gmail.com</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-765cf92e sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="765cf92e" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-68e6e85a sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="68e6e85a" data-element_type="widget" data-widget_type="spacer.default">
                                    <div className="elementor-widget-container">
                                    <div className="elementor-spacer">
                                        <div className="elementor-spacer-inner" />
                                    </div>
                                    </div>
                                </div>
                                <div className="elementor-element elementor-element-311cc402 sc_fly_static elementor-widget elementor-widget-trx_sc_contact_form_7 trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="311cc402" data-element_type="widget" data-widget_type="trx_sc_contact_form_7.default">
                                    <div className="elementor-widget-container">
                                    {successMessage && <div className='alert alert-success'>{successMessage}</div>}
                                    {errorMessage && <div className='alert alert-error'>{errorMessage}</div>}
                                    <div className="wpcf7 js alert_inited" id="wpcf7-f232-p29527-o1" lang="en-US" dir="ltr">
                                        <form onSubmit={handleSubmit} className="wpcf7-form init" aria-label="Contact form" noValidate="novalidate" data-status="init">
                                        <div className="form-style-3">
                                            <div className="columns_wrap">
                                                <div className="column-1_2">
                                                    <p>
                                                        <span className="style-line icon-name">
                                                            <span className="wpcf7-form-control-wrap" data-name="your-name">
                                                                <input size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" placeholder={t("form.name")} type="text" name="name" value={formData.name} onChange={handleChange} />
                                                                <span className="line" />
                                                                {errors.name && <p style={{ color: '#ed2c28', fontSize: '13px' }}>{errors.name}</p>}
                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="column-1_2">
                                                    <p>
                                                        <span className="style-line icon-email">
                                                            <span className="wpcf7-form-control-wrap" data-name="your-email">
                                                                <input size={40} className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email fill_inited" placeholder={t("form.email")} type="email" name="email" value={formData.email} onChange={handleChange} />
                                                                <span className="line" />
                                                                {errors.email && <span style={{ color: '#ed2c28', fontSize: '13px' }}>{errors.email}</span>}
                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="columns_wrap">
                                                <div className="column-1_2">
                                                    <p>
                                                        <span className="style-line icon-phone">
                                                            <span className="wpcf7-form-control-wrap" data-name="phone">
                                                                <input size={40} className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel fill_inited" placeholder={t("form.phone")} type="tel" name="phone" value={formData.phone} onChange={handleChange} />
                                                                <span className="line" />
                                                                {errors.phone && <p style={{ color: '#ed2c28', fontSize: '13px' }}>{errors.phone}</p>}
                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="column-1_2">
                                                    <p>
                                                        <span className="style-line icon-subject">
                                                            <span className="wpcf7-form-control-wrap" data-name="subject">
                                                                <input size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" placeholder={t("form.subject")} type="text" name="subject" value={formData.subject} onChange={handleChange} />
                                                                <span className="line" />
                                                                {errors.subject && <p style={{ color: '#ed2c28', fontSize: '13px' }}>{errors.subject}</p>}
                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="columns_wrap margin-bottom">
                                                <div className="column-1_1">
                                                    <p>
                                                        <span className="style-line icon-textarea">
                                                            <span className="wpcf7-form-control-wrap" data-name="your-message">
                                                                <textarea cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required fill_inited" placeholder={t("form.description")} name="description" value={formData.description} onChange={handleChange} />
                                                                <span className="line" />
                                                                {errors.description && <p style={{ color: '#ed2c28', fontSize: '13px' }}>{errors.description}</p>}
                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <p>
                                                <span className="wpcf7-submit-style">
                                                    <button type='submit' className="wpcf7-form-control wpcf7-submit has-spinner">{t("form.submit")}</button>
                                                    <span className="submit-style-in" />
                                                </span>
                                            </p>
                                        </div>
                                        </form>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </section>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="elementor-section elementor-top-section elementor-element elementor-element-da4de93 scheme_default elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="da4de93" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <div className="elementor-container elementor-column-gap-extended">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7619ca1 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="7619ca1" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-a624264 sc_height_huge scheme_light sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="a624264" data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                                <div className="elementor-spacer-inner" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="elementor-section elementor-top-section elementor-element elementor-element-b35b84a elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="b35b84a" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <div className="elementor-container elementor-column-gap-no">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d92b45a sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="d92b45a" data-element_type="column">
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-0a4c225 sc_fly_static elementor-widget elementor-widget-trx_sc_googlemap trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="0a4c225" data-element_type="widget" data-widget_type="trx_sc_googlemap.default">
                                    <div className="elementor-widget-container">
                                        <div id="sc_googlemap_758017814_wrap" className="sc_googlemap_wrap">
                                            <div id="sc_googlemap_758017814" className="sc_item_content sc_map sc_googlemap sc_googlemap_default trx_addons_inline_446485731" data-zoom={12} data-center data-style="extra" data-cluster-icon>
                                                <iframe title='gg-map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12055.386914067483!2d108.3274749933203!3d15.887814219435576!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31420fd52f526bfd%3A0x643cf24b0856f5cb!2zxJDhu6ljIFRow6BuaCBMYW50ZXJuIE1ha2luZyBDbGFzcw!5e0!3m2!1svi!2s!4v1726018173179!5m2!1svi!2s" aria-label="One" className="corgan_resize trx_addons_resize" style={{height: '100%'}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </div>
            </div>
            </article>
        </div>
        </>
    );
}

export default Contact;
