import { useContext } from 'react';
import ProductItem from "./ProductItem";
import { AppContext } from '../hooks/AppProvider';

function ProductList() {
    const { products } = useContext(AppContext)
    const sortedProducts = products
        .sort((a, b) => b.timestamp - a.timestamp) // Sort by timestamp in descending order
        .slice(0, 6);

    return (
        <div className="elementor-element elementor-element-36f1971 animation_type_sequental sc_fly_static elementor-invisible elementor-widget elementor-widget-trx_sc_extended_products" data-element_type="widget" style={{padding: '0 5%'}}
        data-widget_type="trx_sc_extended_products.default">
            <div className="elementor-widget-container">
                <div className="sc_extended_products sc_extended_products_default">
                    <div className="sc_extended_products_content sc_item_content">
                        <div className="woocommerce columns-3 ">
                            <ul className="products products_style_default columns-3">
                                {
                                    sortedProducts.map((product, index) => (
                                        <ProductItem key={index} product={product} />
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductList;
