import React, { useState, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import lanternLogo from './../assets/images/lantern_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faArrowRight, faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { AppContext } from '../hooks/AppProvider';

function Header() {
    const { language } = useContext(AppContext);
    const { t } = useTranslation();

    const [active, setActive] = useState(language)

    const [isActive, setIsActive] = useState(false);

    const toggleClosePopup = () => {
        setIsActive(!isActive);
    };
    return (
        <>
            <header className="top_panel top_panel_custom top_panel_custom_31289 top_panel_custom_header-main without_bg_image">
                <div className="elementor elementor-31289" data-elementor-id="31289" data-elementor-type="cpt_layouts">
                    <section className='temp-header-top'></section>
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-225b8e7 elementor-section-full_width elementor-section-content-middle sc_layouts_row sc_layouts_row_type_compact sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile scheme_dark sc_layouts_row_fixed sc_layouts_row_fixed_always sc_layouts_row_hide_unfixed elementor-section-height-default elementor-section-height-default sc_fly_static sc_layouts_row_fixed_with_fullscreen sc_layouts_row_fixed_on header-animation" data-id="225b8e7">
                        <div className="elementor-container elementor-column-gap-no">
                            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-aa4fead sc_layouts_column_align_left sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-element_type="column" data-id="aa4fead">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="sc_layouts_item elementor-element elementor-element-43b99ce sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_logo" data-element_type="widget" data-id="43b99ce" data-widget_type="trx_sc_layouts_logo.default">
                                        <div className="elementor-widget-container">
                                            <NavLink to="/" exact="true" className="sc_layouts_logo sc_layouts_logo_default trx_addons_inline_714098060">
                                                <img alt="Lantern" className="logo_image" height="62" src={lanternLogo} srcSet={lanternLogo} width="166" />
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="sc_layouts_item elementor-element elementor-element-23c63ec sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_menu" data-element_type="widget" data-id="23c63ec" data-widget_type="trx_sc_layouts_menu.default">
                                        <div className="elementor-widget-container">
                                            <nav className="sc_layouts_menu_default sc_layouts_menu_dir_horizontal menu_hover_zoom_line hide_on_mobile" data-animation-in="none" data-animation-out="none">
                                                <ul className="sc_layouts_menu_nav menu_main_nav" id="menu_main">
                                                    <li className="menu-item menu-item-type-custom" id="menu-item-29544">
                                                        <NavLink to="/" exact="true" className={({ isActive }) => (isActive ? 'nav-link-active' : '')}>{t('home')}</NavLink>
                                                    </li>
                                                    <li className="menu-item menu-item-type-custom" id="menu-item-17792">
                                                        <NavLink to="/product" exact="true" className={({ isActive }) => (isActive ? 'nav-link-active' : '')} >{t('product')}</NavLink>
                                                    </li>
                                                    <li className="menu-item menu-item-type-custom" id="menu-item-29578">
                                                        <NavLink to="/contact" exact="true" className={({ isActive }) => (isActive ? 'nav-link-active' : '')}>{t('contact')}</NavLink>
                                                    </li>
                                                    <li className="menu-item menu-item-type-custom" id="menu-item-29578">
                                                        <NavLink to="/activity" exact="true" className={({ isActive }) => (isActive ? 'nav-link-active' : '')}>{t('news')}</NavLink>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-755f3f9 sc_layouts_column_align_right sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-element_type="column" data-id="755f3f9">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="sc_layouts_item elementor-element elementor-element-deb1361 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_search" data-element_type="widget" data-id="deb1361" data-widget_type="trx_sc_layouts_search.default">
                                        <LanguageSwitcher active={active} setActive={setActive} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-14ad2c3 sc_layouts_row sc_layouts_row_type_compact elementor-section-content-middle sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                    data-element_type="section" data-id="14ad2c3">
                        <div className="elementor-container elementor-column-gap-extended">
                            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-72d33ee sc_layouts_column_align_left sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-element_type="column" data-id="72d33ee">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="sc_layouts_item elementor-element elementor-element-a328480 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_logo" data-element_type="widget" data-id="a328480" data-widget_type="trx_sc_layouts_logo.default">
                                        <div className="elementor-widget-container">
                                            <NavLink to="/" exact="true" className="sc_layouts_logo sc_layouts_logo_default trx_addons_inline_1590464376">
                                                <img alt="Lantern" className="logo_image" height="62" src={lanternLogo} srcSet={lanternLogo} width="166" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7f3b3fb sc_layouts_column_align_right sc_layouts_column sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static"
                            data-element_type="column" data-id="7f3b3fb">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="sc_layouts_item elementor-element elementor-element-3262cbd sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_menu" data-element_type="widget" data-id="3262cbd" data-widget_type="trx_sc_layouts_menu.default">
                                        <div className="elementor-widget-container">
                                            <div className="sc_layouts_iconed_text sc_layouts_menu_mobile_button_burger sc_layouts_menu_mobile_button">
                                                <a className="sc_layouts_item_link sc_layouts_iconed_text_link" href="/#" onClick={(e) => { e.preventDefault(); toggleClosePopup(); }}>
                                                    <FontAwesomeIcon icon={faBars} size="lg" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </header>
            <div className="menu_mobile_overlay scheme_dark" />
            <div className={`menu_mobile menu_mobile_fullscreen scheme_dark ${isActive ? 'opened' : ''}`} style={{maxWidth: '100vw'}}>
                <div className="menu_mobile_inner with_widgets">
                    <div className="menu_mobile_header_wrap">
                        <NavLink to="/" exact="true" className="sc_layouts_logo">
                            <img alt="lantern" className="logo_image" height="62" src={lanternLogo} srcSet={lanternLogo} width="166" />
                        </NavLink>
                        <a className="menu_mobile_close menu_button_close" tabIndex="0" href="/#" onClick={(e) => { e.preventDefault(); toggleClosePopup(); }}>
                            <FontAwesomeIcon className="menu_button_close_icon" icon={faTimes} size="2x" />
                        </a>
                    </div>
                    <div className="menu_mobile_content_wrap content_wrap">
                        <div className="menu_mobile_content_wrap_inner">
                            <nav className="menu_mobile_nav_area" itemScope itemType="https://schema.org/SiteNavigationElement">
                                <ul id="menu_mobile_1279140404">
                                    <li className="menu-item menu-item-type-custom padding-up-down_10px" style={{textTransform: "uppercase"}}>
                                        <NavLink to="/" className={({ isActive }) => (isActive ? 'nav-link-mobile-active' : '')} exact="true" onClick={() => { toggleClosePopup(); }}>{t('home')} <FontAwesomeIcon icon={faArrowRight} size="xs" style={{ marginLeft: '15px' }} /></NavLink>
                                    </li>
                                    <li className="menu-item menu-item-type-custom padding-up-down_10px" style={{textTransform: "uppercase"}}>
                                        <NavLink to="/product" className={({ isActive }) => (isActive ? 'nav-link-mobile-active' : '')} exact="true" onClick={() => { toggleClosePopup(); }}>{t('product')} <FontAwesomeIcon icon={faArrowRight} size="xs" style={{ marginLeft: '15px' }} /></NavLink>
                                    </li>
                                    <li className="menu-item menu-item-type-custom padding-up-down_10px" style={{textTransform: "uppercase"}}>
                                        <NavLink to="/contact" className={({ isActive }) => (isActive ? 'nav-link-mobile-active' : '')} exact="true" onClick={() => { toggleClosePopup(); }}>{t('contact')} <FontAwesomeIcon icon={faArrowRight} size="xs" style={{ marginLeft: '15px' }} /></NavLink>
                                    </li>
                                    <li className="menu-item menu-item-type-custom padding-up-down_10px" style={{textTransform: "uppercase"}}>
                                        <NavLink to="/activity" className={({ isActive }) => (isActive ? 'nav-link-mobile-active' : '')} exact="true" onClick={() => { toggleClosePopup(); }}>{t('news')} <FontAwesomeIcon icon={faArrowRight} size="xs" style={{ marginLeft: '15px' }} /></NavLink>
                                    </li>
                                </ul>
                            </nav>
                            <div className="socials_mobile" style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                                <div>
                                    <a className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons" rel="noreferrer" href="https://www.facebook.com/ducthanhlantern/" target="_blank">
                                        <FontAwesomeIcon icon={faFacebook} size="1x" />
                                    </a>
                                    <a className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons" rel="noreferrer" href="https://www.tripadvisor.com/Attraction_Review-g298082-d26453947-Reviews-Duc_Thanh_Latern_Making_Class-Hoi_An_Quang_Nam_Province.html" target="_blank">
                                        <FontAwesomeIcon icon={faBinoculars} size="1x" />
                                    </a>
                                    <a className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons" rel="noreferrer" href="https://www.tiktok.com/@ducthanhlantern" target="_blank">
                                        <FontAwesomeIcon icon={faTiktok} size="1x" />
                                    </a>
                                </div>
                                <div>
                                    <LanguageSwitcher active={active} setActive={setActive} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="menu_mobile_widgets_area">
                        <div className="widgets_additional_menu_mobile_fullscreen widgets_additional_menu_mobile_fullscreen_wrap widget_area">
                            <div className="widgets_additional_menu_mobile_fullscreen_inner widgets_additional_menu_mobile_fullscreen_inner widget_area_inner">
                            <aside className="widget_text widget widget_custom_html">
                                <div className="textwidget custom-html-widget">
                                <div className="extra_item">
                                    <h6>Have a question?</h6>
                                    <a href="mailto:ducthanlantern@gmail.com" className="underline_anim underline_do_hover">ducthanlantern@gmail.com</a>
                                </div>
                                <div className="extra_item">
                                    <h6>Want to Contact With Us?</h6>
                                    <Link to='/contact' onClick={(e) => {toggleClosePopup(e); }}>Send contact</Link>
                                </div>
                                </div>
                            </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  }

  export default Header;
