import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Show or hide the button depending on scroll position
        const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    const scrollToTop = () => {
        let currentScroll = window.pageYOffset;
        const scrollStep = currentScroll / 50; // Adjust this value to make the scroll slower or faster

        const scrollAnimation = () => {
          if (currentScroll > 0) {
            window.scrollTo(0, currentScroll);
            currentScroll -= scrollStep;
            requestAnimationFrame(scrollAnimation);
          }
        };

        requestAnimationFrame(scrollAnimation);
    };

    return (
        <div onClick={scrollToTop} className={`trx_addons_scroll_to_top scroll_to_top_style_default ${isVisible ? 'show' : '' }`} title="Scroll to top">
            <FontAwesomeIcon icon={faArrowUp} size="lg" />
        </div>
    );
}

export default ScrollToTop;
