import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AutoScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top-left corner of the window
  }, [pathname]); // Run effect when the pathname changes

  return null; // This component does not render anything
};

export default AutoScrollToTop;
