function Loading() {
    return (
        <div className="spinner-container">
          <div className="spinner"></div>
          <div className="loading-text">
              <span className="loading-icon">⏳</span> {/* Optional loading icon */}
              Loading...
          </div>
        </div>
    );
}

export default Loading;
