import axiosClient from "./axiosClient";

const getAllApi = {
    getAllCategory: (params) => {
        const url = '/categories';
        return axiosClient.get(url, { params });
    },
    getAllProduct: (params) => {
        const url = '/products';
        return axiosClient.get(url, { params });
    },
    getAllNews: (params) => {
        const url = '/news';
        return axiosClient.get(url, { params });
    }
}

export default getAllApi;
