import { useState, useEffect, createContext } from "react";
import getAllApi from "../api/getAllApi";
import "./../assets/css/Spinner.css"
import Loading from "../components/Loading";

export const AppContext = createContext();

function AppProvider({children}) {
    const [loading, setLoading] = useState(true);
    const [language, setLanguage] = useState('en');
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [listNews, setListNews] = useState([]);

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                let dataFetchCategories = await getAllApi.getAllCategory();
                if (dataFetchCategories.data !== null) {
                    setCategories(dataFetchCategories.data)
                }

                let dataFetchProducts = await getAllApi.getAllProduct();
                if (dataFetchProducts.data !== null) {
                    setProducts(dataFetchProducts.data)
                }

                let dataFetchNews = await getAllApi.getAllNews();
                if (dataFetchNews.data !== null) {
                    setListNews(dataFetchNews.data)
                }
            } catch (error) {
                console.error(error)
            }
        }
        fetchAllData();

        setLoading(false);
    }, []);

    if (loading) {
        // Render the loading spinner while data is being fetched
        return <Loading />;
    }

    return (
        <AppContext.Provider
            value={
                {
                    products,
                    loading,
                    categories,
                    listNews,
                    language,
                    setLanguage
                }
            }
        >
            { children }
        </AppContext.Provider>
    );
}

export default AppProvider;

