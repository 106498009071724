import axiosClient from "./axiosClient";

const contactApi = {
    postContact: (body) => {
        const url = '/contact';
        return axiosClient.post(url, body);
    }
}

export default contactApi;
