import Banner from "../components/Banner";
import NewestProduct from "../components/NewestProduct";
import ListCategory from "../components/ListCategory";
import ListNews from "../components/ListNews";

function Home() {
    return (
        <>
            <Banner />
            <div className="page_content_wrap">
                <div className="content_wrap_fullscreen">
                    <div className="content">
                        <article className="post_item_single post_type_page post-33655 page type-page status-publish hentry" id="post-33655">
                            <div className="post_content entry-content">
                                <div className="elementor elementor-33655" data-elementor-id="33655" data-elementor-type="wp-page">
                                    <NewestProduct />
                                    <ListCategory />
                                    <ListNews />
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </>
  );
}

export default Home;
