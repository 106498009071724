import { Link } from 'react-router-dom';
import { AppContext } from '../hooks/AppProvider';
import { useContext } from 'react';

function ProductItem({product}) {
    const { language } = useContext(AppContext)
    return (
        <li className="product type-product status-publish first instock product_cat-wood product_tag-design product_tag-home has-post-thumbnail sale shipping-taxable purchasable product-type-simple product_style_default">
            <div className="post_item post_layout_thumbs animated-item animated corgan-fadeinup">
                <div className="post_featured hover_shop">
                    <Link to={`/product/${product.id}`}>
                        <img className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" decoding="async" height="630" alt="product 1" sizes="(max-width: 630px) 100vw, 630px" src={product.image} width="630" />
                    </Link>
                    <div className="mask" />
                </div>
                <div className="post_data">
                    <div className="post_data_inner">
                        <div className="post_header entry-header">
                            <h2 className="woocommerce-loop-product__title">
                                <Link to={`/product/${product.id}`}>
                                    {language === 'en' ? product.name_en : product.name_vi}
                                </Link>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default ProductItem;
