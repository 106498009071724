import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function NotFound() {
    return (
        <>
            <div className="page_content_wrap">
                <div className="content_wrap">
                    <div className="content">
                        <article className="post_item_single post_item_404 post-0 unknown type-unknown status-publish hentry">
                            <div className="post_content">
                                <h1 className="page_title">404</h1>
                                <div className="page_info">
                                    <h2 className="page_subtitle">Oops...</h2>
                                    <p className="page_description">We're sorry, but <br />something went wrong.</p>
                                    <NavLink to="/" exact="true" className="go_home theme_button sc_button sc_button_decoration">Homepage <FontAwesomeIcon icon={faArrowRight} size="xs" style={{ marginLeft: '15px' }} /></NavLink>
                                </div>
                                <div className="all-svg">
                                    <span className="svg-2" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(1.7714px, -3.3501px)'}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 97"> <g> <circle cx="48.5" cy="48.5" r="48.5" stroke="none" /> <circle cx="48.5" cy="48.5" r={38} fill="none" /> </g> </svg></span><span className="svg-3" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(1.1273px, -2.1319px)'}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.789 59.79"><path d="M-1398.5-175.006l16.063-16.063-16.063-16.063,13.832-13.832L-1368.6-204.9l16.063-16.062,13.831,13.831-16.062,16.063,16.063,16.063-13.831,13.831-16.063-16.062-16.063,16.063Z" transform="translate(1398.498 220.964)" /></svg></span><span className="svg-4" style={{translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0.8857px, -1.6751px)'}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.789 59.79"><path d="M-1398.5-175.006l16.063-16.063-16.063-16.063,13.832-13.832L-1368.6-204.9l16.063-16.062,13.831,13.831-16.062,16.063,16.063,16.063-13.831,13.831-16.063-16.062-16.063,16.063Z" transform="translate(1398.498 220.964)" /></svg></span>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotFound;
