import axios from 'axios';
import queryString from 'query-string';

const axiosClient = axios.create({
    baseURL: "https://f05oy9e2o4.execute-api.ap-southeast-1.amazonaws.com/api/v1",
    // baseURL: "http://localhost:9090/api/v1",
    headers: {
        'content-type': 'application/json',
    },
    paramsSerializer: params => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
    const token = "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJhYmFmNmQwNDA3M2I2MDU1NjYxMmJhMzI5MGUxMmJhNCIsInN1YiI6IjViMTBhM2Y0MGUwYTI2MzA4NTAwZGU1NiIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.qGX4Z7XyFA-IM8pRLknBhBqHa6FbCPvEGSW73bFbNOA";
    config.headers.Authorization = `Bearer ${token}`;

    return config;
})

axiosClient.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }
    return response;
}, (error) => {
    // Handle errors
    throw error;
});

export default axiosClient;
