import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../hooks/AppProvider';

function NewsItem({news}) {
    const { language } = useContext(AppContext)
    return (
        <div className="column-1_2">
            <div id="post-32218" className="related_item post_format_standard post-32218 post type-post status-publish format-standard has-post-thumbnail hentry category-furniture tag-flooring tag-furniture tag-interior" data-post-id={32218}>
                <div className="post_featured with_thumb hover_link">
                    <Link to={`/activity/${news.id}`}>
                        <img width={890} height={664} src={news.image} className="attachment-corgan-thumb-square size-corgan-thumb-square wp-post-image" alt="" decoding="async" loading="lazy" />
                    </Link>
                </div>
                <div className="post_header entry-header">
                    <h6 className="post_title entry-title">
                        <Link to={`/activity/${news.id}`}>
                            {language === "en" ? news.title_en : news.title_vi}
                        </Link>
                    </h6>
                </div>
            </div>
        </div>
    );
}

export default NewsItem;
