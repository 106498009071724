import ProductList from "./ProductList";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NewestProduct() {
    const { t } = useTranslation()
    return (
        <section id="newest-product" className="elementor-section elementor-top-section elementor-element elementor-element-debdccf elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static" data-element_type="section" data-id="debdccf">
            <div className="elementor-container elementor-column-gap-extended">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8e90d01 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static" data-element_type="column" data-id="8e90d01">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-4aa2e13 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="4aa2e13" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-85e30a4 sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-element_type="widget" data-id="85e30a4" data-widget_type="trx_sc_title.default">
                            <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                    <h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                                        <span className="sc_main_title_text">
                                            {t("newest_product")}
                                        </span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-10a622f sc_height_small sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="10a622f" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div>
                        <ProductList />
                        <div className="elementor-element elementor-element-edaf3b0 sc_height_small sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="edaf3b0" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-0f60279 sc_fly_static elementor-widget elementor-widget-trx_sc_button" data-element_type="widget" data-id="0f60279" data-widget_type="trx_sc_button.default">
                            <div className="elementor-widget-container">
                                <div className="sc_item_button sc_button_wrap sc_align_center">
                                    <NavLink to="/product" exact="true" className="sc_button sc_button_decoration sc_button_size_normal sc_button_icon_left color_style_link2 product-button-color">
                                        <div>
                                            <span className="sc_button_title">
                                                {t("all_product")}
                                            </span>
                                            <FontAwesomeIcon icon={faArrowRight} size="xs" style={{marginLeft: "15px"}} />
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NewestProduct;
