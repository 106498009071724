import { useParams } from 'react-router-dom';
import BackDrop from '../components/BackDrop';
import NewsItem from '../components/NewsItem';
import Loading from '../components/Loading';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AppContext } from '../hooks/AppProvider';

function NewsDetail() {
  const {t} = useTranslation()
  const { id } = useParams();
  const { listNews, language } = useContext(AppContext)
  if (!listNews.length) {
    return <Loading />
  }
  const newsItem = listNews.find((item) => item.id === id);
  const newsRelateds = listNews.filter(item =>
    item.id !== newsItem.id
  ).slice(0, 2);

  return (
    <>
      <div className="page_content_wrap single-product">
      <BackDrop title={t("news")} />
      <div className="content_wrap">
        <div className="content">
          <article id="backdrop-down-scroll" className="product-detail__item post_item_single post_type_product">
            <div className="woocommerce-notices-wrapper" />
            <div className="elementor-element elementor-element-16254b0 sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-element_type="widget" style={{marginBottom: '20px'}}>
              <div className="elementor-widget-container">
                <div className="sc_title sc_title_default">
                  <h3 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                    <span className="sc_item_title_text">
                      {language === "en" ? newsItem.title_en : newsItem.title_vi}
                    </span>
                  </h3>
                  <div className="post_meta sc_blogger_item_meta post_meta" style={{display: "flex", justifyContent: "center"}}>
                    <span className="post_meta_item post_date" style={{fontSize: "20px"}}>{newsItem.publish_date}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="elementor-element elementor-element-16254b0 sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-element_type="widget" style={{marginBottom: '20px'}}>
              <div className="elementor-widget-container">
                <img style={{minWidth: "100%"}} src={newsItem.image} className="wp-post-image" alt="" title="image-23-copyright" decoding="async" fetchpriority="high" />
              </div>
            </div>
            <div className="elementor-element elementor-element-16254b0 sc_fly_static elementor-widget elementor-widget-trx_sc_title news-wrapper" data-element_type="widget" style={{marginBottom: '20px'}}>
              <div className="elementor-widget-container" style={{fontFamily: 'sans-serif'}}>
                <div dangerouslySetInnerHTML={{ __html: language === "en" ? newsItem.description_en : newsItem.description_vi }} />
              </div>
            </div>
            <div className="sidebar_hide narrow_content">
              <div className="elementor-element elementor-element-16254b0 sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-element_type="widget" style={{marginBottom: '20px'}}>
                <div className="elementor-widget-container post_item_single post_type_post">
                  <section className="related_wrap related_position_below_content related_style_classic news-wrapper">
                    <h3 className="section_title related_wrap_title">{t("news_page.related_news")}</h3>
                    <div className="columns_wrap posts_container columns_padding_bottom inited_open_full_post">
                      {
                        newsRelateds.map((news, index) => (
                            <NewsItem key={index} news={news} />
                        ))
                      }
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div className="elementor-element elementor-element-10a622f sc_height_small sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="10a622f" data-widget_type="spacer.default">
              <div className="elementor-widget-container">
                <div className="elementor-spacer">
                  <div className="elementor-spacer-inner" />
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    </>
  );
}

export default NewsDetail;
