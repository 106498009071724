const LimitedContent = ({ content }) => {
  const truncateContent = (htmlContent, maxLength) => {
    const div = document.createElement('div');
    div.innerHTML = htmlContent;
    const text = div.innerText || div.textContent;

    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const limitedContent = truncateContent(content, 50);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: limitedContent }}
    />
  );
};

export default LimitedContent;
