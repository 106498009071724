import { useState, useEffect, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import BackDrop from '../components/BackDrop';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../hooks/AppProvider';
import LimitedContent from '../hooks/LimitedContent';
import { NavLink } from 'react-router-dom';

function NewsList() {
    const {t} = useTranslation()
    const { listNews, language } = useContext(AppContext)

    const recentNews = listNews
        .sort((a, b) => b.timestamp - a.timestamp) // Sort by timestamp in descending order
        .slice(0, 2);

    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 6; // Set to 6 items per page

    // Implement debounce
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
            setCurrentPage(0); // Reset to first page on search
        }, 300); // 300ms debounce time

        // Cleanup function to clear the timeout if the effect is called again
        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    // Filter items based on the search term
    const filteredItems = listNews.filter(item =>
        item.title_en.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        item.title_vi.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );

    // Calculate the total number of pages
    const pageCount = Math.ceil(filteredItems.length / itemsPerPage);

    // Calculate the current items to display based on the current page
    const currentItems = filteredItems.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    // Handle page change
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    return (
        <>
            <div className="page_content_wrap sidebar_show sidebar_right sidebar_small_screen_below">
                <BackDrop title={t("news")} />
                <div id="backdrop-down-scroll" className="content_wrap">
                    <div className="elementor-element elementor-element-10a622f sc_height_small sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="10a622f" data-widget_type="spacer.default">
                        <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                                <div className="elementor-spacer-inner" />
                            </div>
                        </div>
                    </div>
                    <div className="content product-page-list">
                        <div className="list_products shop_mode_thumbs">
                            {currentItems.length === 0 ? <p className="woocommerce-result-count">{t("news_page.no_news")}</p> : <></>}
                            <div className="posts_container band_wrap band_1 inited_open_full_post">
                            {
                                currentItems.map((newsItem, index) => (
                                    <article key={index} className="post_item post_item_container post_layout_band post_format_standard post-2588 post type-post status-publish format-standard has-post-thumbnail hentry category-furnishings tag-interior tag-joinery tag-renovation">
                                        <div className="post_featured with_thumb hover_link post_featured_bg" data-ratio="1:1">
                                            <NavLink to={`/activity/${newsItem.id}`}>
                                                <img alt={newsItem.title_en} src={newsItem.image} decoding="async"/>
                                            </NavLink>
                                        </div>
                                        <div className="post_content_wrap">
                                            <div className="post_header entry-header">
                                                <h4 className="post_title entry-title">
                                                    <NavLink to={`/activity/${newsItem.id}`}>
                                                        {language === "en" ? newsItem.title_en : newsItem.title_vi}
                                                    </NavLink>
                                                </h4>
                                            </div>
                                            <div className="post_content entry-content">
                                                <LimitedContent content={language === 'en' ? newsItem.description_en : newsItem.description_vi} />
                                            </div>
                                            <div className="post_meta">
                                                <span className="post_meta_item post_date">
                                                    {newsItem.publish_date}
                                                </span>
                                            </div>
                                        </div>
                                    </article>
                                ))
                            }
                            </div>

                            <nav className="woocommerce-pagination">
                            <ReactPaginate
                                previousLabel={currentPage > 0 ? ' ← ' : null}
                                nextLabel={currentPage < pageCount - 1 ? ' → ' : null}
                                breakLabel={'...'}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                activeClassName={'active-action-paginate'}
                                previousClassName={currentPage === 0 ? 'hidden-action-paginate' : ''}
                                nextClassName={currentPage === pageCount - 1 || filteredItems.length === 0 ? 'hidden-action-paginate' : ''}
                                forcePage={currentPage} // Ensure the correct page is displayed
                            />
                            </nav>
                        </div>
                    </div>
                    <div className="sidebar widget_area right sidebar_below sidebar_default scheme_light" role="complementary">
                        <div className="sidebar_inner">
                            <aside className="widget woocommerce widget_product_search"><h5 className="widget_title">{t("news_page.search")}</h5>
                                <div className="search_form search-container">
                                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                                    <input type="text" className="search_field fill_inited search-input" placeholder={t("news_page.placeholder")} value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                                </div>
                            </aside>
                            <aside className="widget widget_recent_posts">
                                <h5 className="widget_title">{t('news_page.recent_post')}</h5>
                                {
                                    recentNews.map((newsItem, index) => (
                                        <article key={index} className="post_item with_thumb">
                                            <div className="post_thumb hover_zoom">
                                                <NavLink to={`/activity/${newsItem.id}`}>
                                                    <img width={120} height={120} src={newsItem.image} className="attachment-corgan-thumb-tiny size-corgan-thumb-tiny wp-post-image" alt="The foundation of comfort: house framing explained" decoding="async" loading="lazy" sizes="(max-width: 120px) 100vw, 120px" style={{height: '100%'}} />
                                                </NavLink>
                                            </div>
                                            <div className="post_content">
                                                <div className="post_info">
                                                    <span className="post_info_item post_info_posted">
                                                        <span className="post_info_date">{newsItem.publish_date}</span>
                                                    </span>
                                                </div>
                                                <h6 className="post_title">
                                                    <NavLink to={`/activity/${newsItem.id}`}>
                                                        {language === 'en' ? newsItem.title_en : newsItem.title_vi}
                                                    </NavLink>
                                                </h6>
                                            </div>
                                        </article>
                                    ))
                                }
                            </aside>
                        </div>
                    </div>
                    <div className="clearfix" />
                </div>
                <div className="elementor-element elementor-element-10a622f sc_height_small sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="10a622f" data-widget_type="spacer.default">
                    <div className="elementor-widget-container">
                        <div className="elementor-spacer">
                            <div className="elementor-spacer-inner" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewsList;
