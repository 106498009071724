import BannerProductSrc from "./../assets/images/banner.png"
import BannerActivitySrc from "./../assets/images/banner1.png"
import BannerProductMobileSrc from "./../assets/images/banner_product_mobile.png"
import BannerActivityMobileSrc from "./../assets/images/banner_activity_mobile.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import { Autoplay, EffectFade } from 'swiper/modules';
import { NavLink } from "react-router-dom";

const Banner = () => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(0); // To track active slide index
    const swiperRef = useRef(null);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.realIndex); // Correctly handle active index in looped mode
    };

    const scrollToSection = () => {
        document.getElementById('newest-product').scrollIntoView({
            behavior: 'smooth'
        });
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 767);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Swiper
            onSlideChange={handleSlideChange}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            modules={[Autoplay, EffectFade]} // Include Autoplay in modules
            spaceBetween={0} // Adjust spacing between slides if needed
            slidesPerView={1}
            loop={true}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false // Ensures autoplay continues after user interaction
            }}
            speed={2000}
            pagination={{ clickable: true }}
            navigation
            style={{ overflow: 'visible', height: 'auto' }}
            effect="fade"
        >
            <SwiperSlide>
                <div className="slide-container">
                    <NavLink to="/product">
                        <img src={isMobile ? BannerProductMobileSrc : BannerProductSrc} alt="Slide 1" className={activeIndex === 0 ? 'slide-image animate-image' : 'slide-image'} />
                        <div className="text-banner">
                            {t('banner_text.line1')}<br/>
                            {t('banner_text.line2')}<br/>
                            {t('banner_text.line3')}
                        </div>
                    </NavLink>
                    <div className="scroll-indicator" onClick={scrollToSection}>
                        <span className="font-family_utm_bebas">{t('scroll_down')} <FontAwesomeIcon icon={faArrowDown} size="1x" className='arrow-down-icon' /></span>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slide-container">
                    <NavLink to="/activity">
                        <img src={isMobile ? BannerActivityMobileSrc : BannerActivitySrc} alt="Slide 2" className={activeIndex === 1 ? 'slide-image animate-image' : 'slide-image'} />
                        <div className="text-banner-right">
                            {t('banner_text1.line1')}<br/>
                            {t('banner_text1.line2')}<br/>
                            {t('banner_text1.line3')}
                        </div>
                    </NavLink>
                    <div className="scroll-indicator" onClick={scrollToSection}>
                        <span className="font-family_utm_bebas">{t('scroll_down')} <FontAwesomeIcon icon={faArrowDown} size="1x" className='arrow-down-icon' /></span>
                    </div>
                </div>

            </SwiperSlide>
        </Swiper>
    );
};

export default Banner;
