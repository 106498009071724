import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import ProductList from './pages/ProductList.js';
import Contact from './pages/Contact.js';
import Header from './components/Header.js';
import NotFound from './pages/NotFound.js';
import Footer from './components/Footer.js'
import ProductDetail from './pages/ProductDetail.js';
import NewsDetail from './pages/NewsDetail.js';
import ScrollToTop from './components/ScrollToTop.js';
import AppProvider from './hooks/AppProvider.js';
import AutoScrollToTop from './components/AutoScrollToTop.js';

import './App.css'

import "./utils/i18n.js"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./assets/css/main.css"
import NewsList from './pages/NewsList.js';

function App() {
  return (
    <Router>
      <AppProvider>
          <AutoScrollToTop />
          <Header />
          <Routes>
            <Route index path="" element={<Home />}  />
            <Route path="/product" element={<ProductList />} />
            <Route path="/product/:id" element={<ProductDetail />} />
            <Route path="/activity" element={<NewsList />} />
            <Route path="/activity/:id" element={<NewsDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
          <ScrollToTop />
      </AppProvider>
    </Router>
  );
}

export default App;
