import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AppContext } from '../hooks/AppProvider';

function ListNews() {
    const {t} = useTranslation()
    const {listNews, language} = useContext(AppContext)
    if (listNews.length === 0) {
        return (
            <>
                <div className="elementor-element elementor-element-1d8c897 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="1d8c897" data-widget_type="spacer.default">
                    <div className="elementor-widget-container">
                        <div className="elementor-spacer">
                            <div className="elementor-spacer-inner" />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <section className="elementor-section elementor-top-section elementor-element elementor-element-3abe1ca elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static" data-element_type="section" data-id="3abe1ca">
            <div className="elementor-container elementor-column-gap-extended">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0aa34ff sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static" data-element_type="column" data-id="0aa34ff">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-1d8c897 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="1d8c897" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-16254b0 sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-element_type="widget" data-id="16254b0" data-widget_type="trx_sc_title.default">
                            <div className="elementor-widget-container">
                                <div className="sc_title sc_title_default">
                                    <h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                                        <span className="sc_item_title_text">
                                            {t("lastest_news")}
                                        </span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-298490e sc_height_small sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="298490e" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-a8562f6 sc_style_default sc_fly_static elementor-widget elementor-widget-trx_sc_blogger" data-element_type="widget" data-id="a8562f6" data-widget_type="trx_sc_blogger.default">
                            <div className="elementor-widget-container">
                                <div className="sc_blogger sc_blogger_default sc_blogger_default_classic sc_item_filters_tabs_none alignnone">
                                    <div className="sc_blogger_slider sc_item_slider slider_swiper_outer slider_outer slider_outer_nocontrols slider_outer_pagination slider_outer_pagination_bullets slider_outer_pagination_pos_bottom_outside slider_outer_nocentered slider_outer_overflow_hidden slider_outer_multi">
                                        <Swiper
                                            modules={[Pagination, Autoplay]}
                                            loop={true}
                                            autoplay={{ delay: 2500, disableOnInteraction: false }}
                                            pagination={{ clickable: true, type: 'bullets' }}
                                            navigation={false} // Set to true if you want navigation arrows
                                            breakpoints={{
                                                320: { slidesPerView: 1, spaceBetween: 20 },
                                                640: { slidesPerView: 1, spaceBetween: 20 },
                                                768: { slidesPerView: 2, spaceBetween: 20 },
                                                1024: { slidesPerView: 4, spaceBetween: 20 },
                                            }}
                                        >
                                            {
                                                listNews.map((news, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic sc_blogger_item_odd sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_image_position_top post-32210 post type-post status-publish format-standard has-post-thumbnail hentry category-furniture tag-flooring tag-furniture tag-interior">
                                                            <div className="sc_blogger_item_body">
                                                                <div className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured">
                                                                    <Link to={`/activity/${news.id}`}>
                                                                        <img alt="" className="attachment-corgan-thumb-square size-corgan-thumb-square wp-post-image" decoding="async" height="664" loading="lazy" src={news.image} width="890" />
                                                                    </Link>
                                                                </div>
                                                                <div className="sc_blogger_item_content entry-content">
                                                                    <h5 className="sc_blogger_item_title entry-title" data-item-number="1">
                                                                        <Link to={`/activity/${news.id}`}>
                                                                            {language === "en" ? news.title_en : news.title_vi}
                                                                        </Link>
                                                                    </h5>
                                                                    <div className="post_meta sc_blogger_item_meta post_meta">
                                                                        <span className="post_meta_item post_date">
                                                                            {news.publish_date}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                            }
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-6fd3d82 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="6fd3d82" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                    <div className="elementor-spacer-inner" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ListNews;
