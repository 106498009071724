import BackDropSrc from './../assets/images/backdrop.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

function BackDrop({title}) {
    const scrollToSection = () => {
        document.getElementById('backdrop-down-scroll').scrollIntoView({
            behavior: 'smooth'
        });
    };

    return (
        <div className="backdrop-container">
            <img src={BackDropSrc} alt="backdrop 1" className='backdrop-image' />
            <div className="text-backdrop">
                <h2>{title}</h2>
                <div className="backdrop-arrow" onClick={scrollToSection}>
                    <span><FontAwesomeIcon icon={faChevronDown} size="2x" className='backdrop-down-icon' /></span>
                </div>
            </div>
        </div>
    );
}

export default BackDrop;
