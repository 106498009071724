import { useParams } from 'react-router-dom';
import BackDrop from '../components/BackDrop';
import ProductItem from '../components/ProductItem';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AppContext } from '../hooks/AppProvider';
import Loading from '../components/Loading';

function ProductDetail() {
  const {t} = useTranslation()
  const { id } = useParams();
  const { products, language, categories } = useContext(AppContext)
  if (!products.length) {
    return <Loading />
  }
  const productItem = products.find((item) => item.id === id);
  const categoryItem = categories.find((item) => item.id === productItem.category_id);
  const productRelateds = products.filter(item =>
    item.category_id === productItem.category_id && item.id !== productItem.id
  ).slice(0, 3);

  return (
    <>
      <div className="page_content_wrap single-product">
      <BackDrop title={t("product")} />
      <div className="content_wrap">
        <div className="content">
          <article id="backdrop-down-scroll" className="product-detail__item post_item_single post_type_product">
            <div className="woocommerce-notices-wrapper" />
            <div className="product type-product status-publish first instock product_cat-wood product_tag-design product_tag-home has-post-thumbnail sale shipping-taxable purchasable product-type-simple">
              <div className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-4 images woocommerce-product-gallery--without-thumbs" data-columns={4} style={{opacity: 1, transition: 'opacity .25s ease-in-out'}}>
                <div className="woocommerce-product-gallery__wrapper">
                  <div className="woocommerce-product-gallery__image" style={{position: 'relative', overflow: 'hidden'}}>
                    <a href="/#" onClick={(e) => {e.preventDefault();}} className='product-item__image' data-elementor-open-lightbox="no">
                      <img width={600} height={600} src={productItem.image} className="wp-post-image" alt="" title="image-23-copyright" decoding="async" fetchpriority="high" sizes="(max-width: 600px) 100vw, 600px" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="summary entry-summary">
                <h1 className="product_title entry-title">{language === 'en' ? productItem.name_en : productItem.name_vi}</h1>
                <div className="woocommerce-product-details__short-description">
                  <div dangerouslySetInnerHTML={{ __html: language === 'en' ? productItem.description_en : productItem.description_vi }} />
                </div>
                <div className="product_meta">
                  <span className="posted_in">Category: <a href="/#" rel="tag">{language === 'en' ? categoryItem.name_en : categoryItem.name_vi }</a></span>
                  <span className="product_id">Product ID: <span>{productItem.id}</span></span>
                </div>
              </div>
              <section className="related products">
                <h2>{t("product_page.related_product")}</h2>
                <ul className="products columns-3">
                  {
                      productRelateds.map((product, index) => (
                          <ProductItem key={index} product={product} />
                      ))
                  }
                </ul>
              </section>
            </div>
            <div className="elementor-element elementor-element-10a622f sc_height_small sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="10a622f" data-widget_type="spacer.default">
              <div className="elementor-widget-container">
                <div className="elementor-spacer">
                  <div className="elementor-spacer-inner" />
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    </>
  );
}

export default ProductDetail;
