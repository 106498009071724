import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTiktok} from '@fortawesome/free-brands-svg-icons';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation()
    return (
        <footer className="footer_wrap footer_custom footer_custom_31017 footer_custom_footer-home-4 scheme_dark">
            <div className="elementor elementor-31017" data-elementor-id="31017" data-elementor-type="cpt_layouts">
                <section className="elementor-section elementor-top-section elementor-element elementor-element-1e0c2ea4 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static" data-element_type="section" data-id="1e0c2ea4"
                data-settings='{"background_background":"classic"}'>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2843cc18 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static" data-element_type="column" data-id="2843cc18">
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="sc_layouts_item elementor-element elementor-element-381666d4 sc_height_large sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="381666d4" data-widget_type="spacer.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="elementor-section elementor-top-section elementor-element elementor-element-79f907b8 sc_layouts_row sc_layouts_row_type_compact scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static"
                data-element_type="section" data-id="79f907b8" data-settings='{"background_background":"classic"}'>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-30489e99 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static" data-element_type="column" data-id="30489e99">
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="sc_layouts_item elementor-element elementor-element-238ea9fa sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-element_type="widget" data-id="238ea9fa" data-widget_type="trx_sc_title.default">
                                    <div className="elementor-widget-container">
                                        <div className="sc_title sc_title_default">
                                            <h6 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                                <span className="sc_item_title_text">Đức thành lantern</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-54cc3857 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static" data-element_type="column" data-id="54cc3857">
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="sc_layouts_item elementor-element elementor-element-e45f8fd sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-element_type="widget" data-id="e45f8fd" data-widget_type="trx_sc_title.default">
                                    <div className="elementor-widget-container">
                                        <div className="sc_title sc_title_default">
                                            <h6 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                                <span className="sc_item_title_text">{t("address")}</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-4fde4af4 sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="4fde4af4" data-widget_type="spacer.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-30f88e3d sc_fly_static elementor-widget elementor-widget-text-editor" data-element_type="widget" data-id="30f88e3d" data-widget_type="text-editor.default">
                                    <div className="elementor-widget-container">
                                        <p>
                                            40 Hai Thuong Lan Ong St.,
                                            <br /> Hoi An, Quang Nam
                                        </p>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-3d80e5fa sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="3d80e5fa" data-widget_type="spacer.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-705d3fa0 sc_fly_static elementor-widget elementor-widget-text-editor" data-element_type="widget" data-id="705d3fa0" data-widget_type="text-editor.default">
                                    <div className="elementor-widget-container">
                                        <p>
                                            <a className="underline_anim" href="mailto:ducthanhlantern@gmail.com">
                                                <span className="__cf_email__" data-cfemail="026b6c646d42676f636b6e2c616d6f">
                                                    ducthanhlantern@gmail.com
                                                </span>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-6448a798 sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="6448a798" data-widget_type="spacer.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-e45f8fd sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-element_type="widget" data-id="e45f8f3" data-widget_type="trx_sc_title.default">
                                    <div className="elementor-widget-container">
                                        <div className="sc_title sc_title_default">
                                            <h6 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                                <span className="sc_item_title_text">{t("phone")}</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-252f2bc9 sc_fly_static elementor-widget elementor-widget-text-editor" data-element_type="widget" data-id="252f2bc9" data-widget_type="text-editor.default">
                                    <div className="elementor-widget-container">
                                        <span className="trx_addons_alter_text">
                                        <a href="tel:0787696763">0787 69 67 63</a>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-316413e4 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static" data-element_type="column" data-id="316413e4">
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="sc_layouts_item elementor-element elementor-element-13a3edf3 sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-element_type="widget" data-id="13a3edf3" data-widget_type="trx_sc_title.default">
                                    <div className="elementor-widget-container">
                                        <div className="sc_title sc_title_default">
                                            <h6 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                                <span className="sc_item_title_text">{t("links")}</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-42f6a980 sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="42f6a980" data-widget_type="spacer.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-386ffec sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static" data-element_type="column" data-id="386ffec">
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="sc_layouts_item elementor-element elementor-element-6ef4c07a sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-element_type="widget" data-id="6ef4c07a" data-widget_type="trx_sc_title.default">
                                    <div className="elementor-widget-container">
                                        <div className="sc_title sc_title_default">
                                            <h6 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                                <span className="sc_item_title_text">
                                                    {t("get_in_touch")}
                                                </span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="sc_layouts_item elementor-element elementor-element-22e9ce23 sc_fly_static elementor-widget elementor-widget-trx_sc_socials" data-element_type="widget" data-id="22e9ce23" data-widget_type="trx_sc_socials.default">
                                    <div className="elementor-widget-container">
                                        <div className="sc_socials sc_socials_icons_names sc_align_none">
                                            <div className="socials_wrap sc_item_content">
                                                <a className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons_names" href="https://www.facebook.com/ducthanhlantern/" target="_blank" rel="noreferrer">
                                                    <FontAwesomeIcon icon={faFacebook} size="1x" style={{marginRight: '5px'}} />
                                                    <span className="social_name social_facebook-1">
                                                        Facebook
                                                    </span>
                                                </a>
                                                <a className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons_names" href="https://www.tripadvisor.com/Attraction_Review-g298082-d26453947-Reviews-Duc_Thanh_Latern_Making_Class-Hoi_An_Quang_Nam_Province.html" target="_blank" rel="noreferrer">
                                                    <FontAwesomeIcon icon={faBinoculars} size="1x" style={{marginRight: '5px'}} />
                                                    <span className="social_name social_instagram">
                                                        Tripadvisor
                                                    </span>
                                                </a>
                                                <a className="social_item social_item_style_icons sc_icon_type_icons social_item_type_icons_names" href="https://www.tiktok.com/@ducthanhlantern" target="_blank" rel="noreferrer">
                                                <FontAwesomeIcon icon={faTiktok} size="1x" style={{marginRight: '5px'}} />
                                                    <span className="social_name social_instagram">
                                                        Tiktok
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="elementor-section elementor-top-section elementor-element elementor-element-1e0c2ea4 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static" data-element_type="section" data-id="1e0c2ea4"
                data-settings='{"background_background":"classic"}'>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2843cc18 sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static" data-element_type="column" data-id="2843cc18">
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="sc_layouts_item elementor-element elementor-element-381666d4 sc_height_large sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="381666d4" data-widget_type="spacer.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </footer>
    );
}

export default Footer;
