import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../hooks/AppProvider';

const LanguageSwitcher = ({ active, setActive }) => {
  const { i18n } = useTranslation();
  const { setLanguage } = useContext(AppContext);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActive(lng);
    setLanguage(lng);
  };

  return (
    <div>
      <a href="/#" className={active === "en" ? "nav-link_language active" : "nav-link_language"} onClick={(e) => { e.preventDefault(); changeLanguage('en'); }}>EN</a>
      {' | '}
      <a href="/#" className={active === "vi" ? "nav-link_language active" : "nav-link_language"} onClick={(e) => { e.preventDefault(); changeLanguage('vi'); }}>VI</a>
    </div>
  );
};

export default LanguageSwitcher;
