import { useState, useEffect, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import BackDrop from '../components/BackDrop';
import ProductItem from '../components/ProductItem';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../hooks/AppProvider';
import { Link, useLocation } from 'react-router-dom';

function ProductList() {
    const {t} = useTranslation()
    const {language, products, categories} = useContext(AppContext)
    // Use the useLocation hook to access the current URL
    const location = useLocation();

    // Create a URLSearchParams object to parse the query parameters
    const queryParams = new URLSearchParams(location.search);
    const categoryId = queryParams.get('category');

    var listProducts = []
    if (categoryId) {
        listProducts = products.filter(item =>
            item.category_id === categoryId
        );
    } else {
        listProducts = products;
    }

    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 6; // Set to 6 items per page

    // Implement debounce
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
            setCurrentPage(0); // Reset to first page on search
        }, 300); // 300ms debounce time

        // Cleanup function to clear the timeout if the effect is called again
        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm, location]);

    // Filter items based on the search term
    const filteredItems = listProducts.filter(item =>
        item.name_en.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        item.name_vi.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );

    // Calculate the total number of pages
    const pageCount = Math.ceil(filteredItems.length / itemsPerPage);

    // Calculate the current items to display based on the current page
    const currentItems = filteredItems.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    // Handle page change
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Calculate the range of items currently being displayed
    const startItem = filteredItems.length ? currentPage * itemsPerPage + 1 : 0;
    const endItem = Math.min((currentPage + 1) * itemsPerPage, filteredItems.length);

    return (
        <>
            <div className="page_content_wrap sidebar_show sidebar_right sidebar_small_screen_below">
                <BackDrop title={t("product")} />
                <div id="backdrop-down-scroll" className="content_wrap">
                    <div className="elementor-element elementor-element-10a622f sc_height_small sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="10a622f" data-widget_type="spacer.default">
                        <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                                <div className="elementor-spacer-inner" />
                            </div>
                        </div>
                    </div>
                    <div className="content product-page-list">
                        <div className="list_products shop_mode_thumbs">
                            <p className="woocommerce-result-count" style={{marginBottom: '20px'}}>{t("product_page.showing")} {startItem} - {endItem} {t("product_page.of")} {filteredItems.length} {t("product_page.results")}</p>
                            <ul className="products products_style_default columns-2">
                                {
                                    currentItems.map((product, index) => (
                                        <ProductItem key={index} product={product} />
                                    ))
                                }
                            </ul>
                            <nav className="woocommerce-pagination">
                            <ReactPaginate
                                previousLabel={currentPage > 0 ? ' ← ' : null}
                                nextLabel={currentPage < pageCount - 1 ? ' → ' : null}
                                breakLabel={'...'}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                activeClassName={'active-action-paginate'}
                                previousClassName={currentPage === 0 ? 'hidden-action-paginate' : ''}
                                nextClassName={currentPage === pageCount - 1 || filteredItems.length === 0 ? 'hidden-action-paginate' : ''}
                                forcePage={currentPage} // Ensure the correct page is displayed
                            />
                            </nav>
                        </div>
                    </div>
                    <div className="sidebar widget_area right sidebar_below sidebar_default scheme_light" role="complementary">
                        <div className="sidebar_inner">
                            <aside className="widget woocommerce widget_product_search"><h5 className="widget_title">{t("product_page.search")}</h5>
                                <div className="search_form search-container">
                                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                                    <input type="text" className="search_field fill_inited search-input" placeholder={t("product_page.placeholder")} value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                                </div>
                            </aside>
                            <aside className="widget woocommerce widget_product_tag_cloud">
                                <h5 className="widget_title">{t("product_page.category")}</h5>
                                <div className="tagcloud">
                                    {
                                        categories.map((category, index) => (
                                            <Link to={`/product?category=${category.id}`} key={index} className={`tag-cloud-link tag-link-204 tag-link-position-${index+1} ${categoryId === category.id ? 'category-active' : ''}`} style={{fontSize: '22pt'}}>
                                                {language === "en" ? category.name_en : category.name_vi}
                                            </Link>
                                        ))
                                    }
                                </div>
                            </aside>
                        </div>
                    </div>
                    <div className="clearfix" />
                </div>
                <div className="elementor-element elementor-element-10a622f sc_height_small sc_fly_static elementor-widget elementor-widget-spacer" data-element_type="widget" data-id="10a622f" data-widget_type="spacer.default">
                    <div className="elementor-widget-container">
                        <div className="elementor-spacer">
                            <div className="elementor-spacer-inner" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductList;
